<template>
  <div>
    <r-combine-dataset
      v-show="selectedItem==='r_combine_dataset'"
      @reset="reset"
      @is-combined="isCombined"
      :minNumericVariable="1"
      :minSample="5"
      :nbDataset="2"
      :analysisObj="getParentCombined()"
    />
    <r-pls-step
      ref="plsstep"
      v-show="selectedItem==='r_pls'"
      :dataset.sync="combinedObjectName"
      :analysisObj="getAnalysisForStep('r_pls')"
      @is-computed="isComputed"
    >
    </r-pls-step>
    <r-plotind
      v-show="selectedItem==='r_plotind'"
      ref="plotind"
      analysisType="pls"
      :datasetName="objectName"
      :nbCP="nAxes" />
    <r-plotvar
      v-show="selectedItem==='r_plotvar'"
      analysisType="pls"
      ref="plotvar"
      :datasetName="objectName"
      :nbCP="nAxes"
      :plsMode="plsMode" />
    <r-extract-obj
      v-if="selectedItem==='r_extract_obj'"
      analysisType="pls"
      :extractSuffix="combinedSuffix"
      :datasetName="objectName"
      datasetPrefix="PLS"
      :nbCP="nAxes" />
  </div>
</template>

<script>
import { menu } from '@/mixins/menu'
import { workflow } from '@/mixins/workflow'
import { useWorkspaceStore } from '@/stores/workspacestore'
import { useMenuStore } from '@/stores/menu'
export default {
  name: 'RPLS',
  components: {
    'r-plotind': () => import('@/components/ranalyses/RPlotind.vue'),
    'r-plotvar': () => import('@/components/ranalyses/RPlotvar.vue'),
    'r-pls-step': () => import('@/components/ranalyses/RPLSStep.vue'),
    'r-combine-dataset': () => import('@/components/ranalyses/RCombineDataset.vue'),
    'r-extract-obj': () => import('@/components/ranalyses/RExtractObj.vue')
  },
  mixins: [menu, workflow],
  data () {
    return {
      menuItems: [
        {
          title: 'Preprocessing',
          name: 'r_combine_dataset',
          disabled: false
        },
        {
          title: 'Run PLS',
          name: 'r_pls',
          disabled: true
        },
        {
          title: 'Explore individuals',
          name: 'r_plotind',
          disabled: true
        },
        {
          title: 'Explore variables',
          name: 'r_plotvar',
          disabled: true
        },
        {
          title: 'Extract new data',
          name: 'r_extract_obj',
          disabled: true
        }
      ],
      combinedObjectName: null,
      combinedSuffix: '',
      objectName: null,
      plsMode: null
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    const menuStore = useMenuStore()
    return { workspaceStore, menuStore }
  },
  computed: {
    analyses: function () {
      return this.workspaceStore.analyses
    },
    nAxes: function () {
      let nAxes = 0
      let analysis = this.analyses.find(obj => obj.object_name === this.objectName)
      if (analysis !== undefined) {
        nAxes = Number.parseInt(analysis.meta.func_args.ncp)
      }
      return nAxes
    }
  },
  methods: {
    reset: function () {
      this.combinedObjectName = null
      this.combinedSuffix = null
      this.menuItems[1].disabled = true
      this.menuItems[2].disabled = true
      this.menuItems[3].disabled = true
      this.menuItems[4].disabled = true
      this.objectName = null
      this.plsMode = null
    },
    isCombined: function (combinedObj) {
      this.combinedObjectName = combinedObj.combinedObjectName
      this.combinedSuffix = combinedObj.combinedDatasets.map(obj => obj.user_name).join('_')
      this.menuItems[1].disabled = false
      this.menuItems[2].disabled = true
      this.menuItems[3].disabled = true
      this.menuItems[4].disabled = true
      this.$refs.plsstep.reset()
    },
    isComputed: function (data) {
      this.objectName = data.plsObj
      this.plsMode = data.plsMode
      this.menuItems[1].disabled = false
      this.menuItems[2].disabled = false
      this.menuItems[3].disabled = false
      this.menuItems[4].disabled = false
      this.menuStore.selectItem(this.menuItems[1])
    }
  },
  mounted () {
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })
    this.workspaceStore.getWorkflow()
      .then(() => {
        loadingComponent.close()
      })
      .catch(() => {
        loadingComponent.close()
      })
  }
}
</script>
